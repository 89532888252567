import React, { useState, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import {
  ChakraProvider,
  Box,
  Grid
} from '@chakra-ui/react';
import { default as darkTheme } from './theme';
import Map, { GeolocateControl } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import * as turf from '@turf/turf';
import { DataFilterButton, MapMenu, LowerPopout, SiteInfo, SitePopup } from './Components';
import { fuelOrder, MAPBOX_BBOX, MAPBOX_TOKEN, apiRoute } from './Globals';

function useData(fetchUrl) {
  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(fetchUrl);

        if (!res.ok) {
          throw new Error(`HTTP Error: ${res.status}`);
        } else {
          const json = await res.json();
          setData(json);
        }
      }
      catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, [fetchUrl]);

  return data;
}

function LivePanel() {
  const [fuelTypeFocus, setFuelTypeFocus] = useState(JSON.parse(window.localStorage.getItem("defaultFuel")) || "Unleaded");
  const [brandFilter, setBrandFilter] = useState(0);
  const [localColourCoding, setLocalColourCoding] = useState(JSON.parse(window.localStorage.getItem("localColourCoding")) || 1);
  const [userRoutes, setUserRoutes] = useState(JSON.parse(window.localStorage.getItem("userRoutes")) || []);
  const brandsList = useData(apiRoute + "/api/getbrands");
  const [localPriceList, setLocalPriceList] = useState();
  const mapAreaLoadedPolygon = useRef();
  const mapData = useRef();
  const mapRef = useRef();
  const popupRef = useRef(new mapboxgl.Popup({closeOnMove: true}));
  const [popoutExpanded, setPopoutExpanded] = useState(0);

  const [popoutContent, setPopoutContent] = useState(null);
  const popupNode = document.createElement("div");
  const popupRoot = createRoot(popupNode);

  useEffect(() => {
    const layerNames = [
      "clusters_",
      "min_price_",
      "unclustered_point_",
      "unclustered_price_"
    ];

    // The source "e10" is arbitrary, it could check for any of the fuel class names.
    // It's purpose is to check if the sources were loaded during the initial page load.
    if (mapRef.current && mapRef.current.getMap().getSource("e10")) {
      const map = mapRef.current.getMap();
      for (let key in fuelOrder) {
        if (fuelOrder[key].dbName === fuelTypeFocus) {
          layerNames.forEach((elem) => {
            map.setLayoutProperty(
              elem + fuelOrder[key].class,
              'visibility',
              'visible'
            );
          });
        } else {
          layerNames.forEach((elem) => {
            map.setLayoutProperty(
              elem + fuelOrder[key].class,
              'visibility',
              'none'
            );
          });
        }
      }
      updateVisuals(getMapPolygon(), map);
    }
  }, [fuelTypeFocus]);

  useEffect(() => {
    updateSources();
  }, [brandFilter]);

  function geoLoc() {
    if ('geolocation' in navigator) {
      let pos = {};
      navigator.geolocation.getCurrentPosition(position => {
        pos.longitude = position.coords.longitude;
        pos.latitude = position.coords.latitude;
        pos.zoom = 4.2;
      });
      return (pos)
    } else {
      return ({
        longitude: 133,
        latitude: -25,
        zoom: 4.2
      })
    }
  };

  const mapInit = JSON.parse(window.localStorage.getItem("mapInit")) || geoLoc();

  function mapFlyTo(location) {
    if (mapRef.current) {
      mapRef.current.getMap().flyTo({
        center: location.center,
        zoom: location.zoom,
        duration: 1200
      });
    }
  };

  function mapSitePopup(coords, properties) {
    const popup = document.getElementsByClassName("mapboxgl-popup");
    if (popup.length) {
      popup[0].remove();
    }
    popupRef.current.remove()
    const map = mapRef.current.getMap();
    function popupOpen() {
      popupRoot.render(<ChakraProvider><SitePopup properties={properties} coords={coords} openSiteInfo={openSiteInfo}/></ChakraProvider>);
      
      popupRef.current
        .setLngLat(coords)
        .setDOMContent(popupNode)
        .addTo(map);
    }
    if (map.isMoving()) {
      map.once('moveend', () => {
        popupOpen();
      });
    } else {
      popupOpen();
    }
  }

  function addRoute(route) {

  };

  function getMapPolygon() {
    const mapBounds = mapRef.current.getMap().getBounds().toArray();
    const mapBbox = mapBounds[0].concat(mapBounds[1]);
    return turf.bboxPolygon(mapBbox);
  }

  async function mapFetch(reqPoly) {
    try {
      const res = await fetch(apiRoute + "/api/mapstream",
        {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
          },
          method: "POST",
          body: JSON.stringify(reqPoly)
        });

      if (!res.ok) {
        throw new Error(`mapFetch Error: ${res.status}`);
      }

      const json = await res.json();
      return json;
    }
    catch (err) {
      return err;
    }
  }

  function getReqPoly(newBounds) {

    // Polygon to use in the map data request, new viewport minus already loaded data or viewport bounds if this is the initial fetch
    const loadedArea = mapAreaLoadedPolygon.current;
    const reqPoly = (loadedArea) ? turf.difference(newBounds, loadedArea) : newBounds;

    return reqPoly;
  }

  function getVisibleFeatures(bounds) {
    const bufferedBounds = turf.buffer(bounds, 5, { units: 'kilometers' });
    const map = mapRef.current.getMap();
    const currentSource = map.getSource(fuelOrder[fuelTypeFocus]["class"])._data;
    const within = turf.pointsWithinPolygon(currentSource, bufferedBounds);
    return within.features;
  }

  function updateLocalPriceList(features) {
    const topFive = features.slice(0, 5);
    setLocalPriceList(topFive);
  }

  function setPriceBounds(features, map) {
    if (!features.length) return;
    const low = features[0].properties.price;
    const high = features[features.length - 1].properties.price;

    const fuelTypeClass = fuelOrder[fuelTypeFocus].class;
    const clusterClass = "clusters_" + fuelTypeClass;
    const unclusteredClass = "unclustered_point_" + fuelTypeClass;

    const rangeDiff = (high - low) / 5;
    const div = Math.max(rangeDiff, 1);
    const priceBins = [
      Number(low + div),
      Number(low + (div * 2)),
      Number(low + (div * 3)),
      Number(low + (div * 4)),
    ];

    const circleColorClustered = [
      'step',
      ['/', ['get', 'priceTotal'], ['get', 'point_count']],
      '#2cba00',
      priceBins[0],
      '#a3ff00',
      priceBins[1],
      '#fff400',
      priceBins[2],
      '#ffa700',
      priceBins[3],
      '#ff0000',
    ];

    const circleColorUnclustered = [
      'step',
      ['get', 'price'],
      '#2cba00',
      priceBins[0],
      '#a3ff00',
      priceBins[1],
      '#fff400',
      priceBins[2],
      '#ffa700',
      priceBins[3],
      '#ff0000',
    ];

    map.setPaintProperty(clusterClass, "circle-color", circleColorClustered);
    map.setPaintProperty(unclusteredClass, "circle-color", circleColorUnclustered);
  }

  function updateVisuals(newBounds, map) {
    const visibleFeatures = getVisibleFeatures(newBounds);
    visibleFeatures.sort((a, b) => {
      if (a.properties.price === b.properties.price) {
        return b.properties.sitescore - a.properties.sitescore;
      }
      return a.properties.price - b.properties.price;
    });

    updateLocalPriceList(visibleFeatures);
    if (localColourCoding) setPriceBounds(visibleFeatures, map);
  }

  function updateSources() {
    if (!mapRef.current) return;
    const map = mapRef.current.getMap();
    const newBounds = getMapPolygon();

    for (const key in fuelOrder) {
      if (!fuelOrder.hasOwnProperty(key)) continue;

      const elem = fuelOrder[key];

      const source = map.getSource(elem.class);
      if (!source) {
        throw new Error("Map sources not loaded!");
      } else {
        if (!brandFilter) {
          source.setData(turf.getCluster(mapData.current, { 'fueltype': elem.dbName }));
        } else {
          source.setData(turf.getCluster(mapData.current, { 'fueltype': elem.dbName, 'brand': brandsList[brandFilter - 1] }));
        }
        updateVisuals(newBounds, map);
      }

    }
  }

  async function mapUpdate() {

    const map = mapRef.current.getMap();
    const newBounds = getMapPolygon();
    const reqPoly = getReqPoly(newBounds);

    try {
      if (!mapData.current) {
        throw new Error("Map Data not loaded!");
      }

      // getReqPoly() returns null if newBounds already exists within loadedArea,
      // meaning the data for that area is already loaded so we don't need update anything.
      if (reqPoly) {
        const res = await mapFetch(reqPoly);

        if (!res) {
          throw new Error(`mapFetch failed!`);
        } else if (!Array.isArray(res)) {
          throw new Error(`mapFetch failed, returned: ${res}`);
        } else {

          // Add the new features to mapData
          mapData.current.features.push(...res);

          updateSources();

          // Add the new viewport bbox to the polygon of already loaded data
          mapAreaLoadedPolygon.current = (mapData.current) ? turf.union(mapAreaLoadedPolygon.current, newBounds) : newBounds;

        }

      }

      updateVisuals(newBounds, map);

    }
    catch (err) {
      console.log(err);
    }

  }

  async function mapDataLoad() {

    document.getElementById("mapMenu").style.display = "";

    const mapBounds = getMapPolygon();
    const reqPoly = getReqPoly(mapBounds);

    try {
      const features = await mapFetch(reqPoly);

      if (!features) {
        throw new Error(`Error: features returned - ${features}`);
      } else {
        mapData.current = turf.featureCollection(features);
        mapAreaLoadedPolygon.current = getMapPolygon();
      }

      let priceBounds = {};

      const bounds = await fetch(apiRoute + "/api/latest/bounds");

      if (!bounds.ok) {
        throw new Error(`Map Bounds Fetch HTTP Error: ${bounds.status}`);
      } else {

        const boundsJson = await bounds.json();
        boundsJson.forEach((elem) => {
          priceBounds[elem._id] = {
            "high": elem.high,
            "low": elem.low
          }
        });

      }

      if (mapRef.current) {
        const map = mapRef.current.getMap();

        for (const key in fuelOrder) {
          if (!fuelOrder.hasOwnProperty(key)) continue;

          const elem = fuelOrder[key];

          const pbElem = priceBounds[elem.dbName];

          const isVisible = (fuelTypeFocus === elem.dbName) ? 'visible' : 'none';

          map.addSource(elem.class, {
            type: "geojson",
            data: turf.getCluster(mapData.current, { 'fueltype': elem.dbName }),
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 50,
            clusterProperties: {
              priceTotal: ['+', ['get', 'price']],
              priceMin:
                ['min', ['get', 'price']],
            }
          });

          const div = (pbElem.high - pbElem.low) / 5;
          const priceBins = [
            Number(pbElem.low + div),
            Number(pbElem.low + (div * 2)),
            Number(pbElem.low + (div * 3)),
            Number(pbElem.low + (div * 4)),
          ];

          map.addLayer({
            id: 'clusters_' + elem.class,
            type: 'circle',
            source: elem.class,
            filter: ['has', 'point_count'],
            layout: { visibility: isVisible },
            paint: {
              'circle-color': [
                'step',
                ['get', 'priceMin'],
                '#2cba00',
                priceBins[0],
                '#a3ff00',
                priceBins[1],
                '#fff400',
                priceBins[2],
                '#ffa700',
                priceBins[3],
                '#ff0000',
              ],
              'circle-radius': 25,
              'circle-stroke-width': 2,
              'circle-stroke-color': '#000',
            }
          });

          map.addLayer({
            id: 'min_price_' + elem.class,
            type: 'symbol',
            source: elem.class,
            filter: ['has', 'point_count'],
            layout: {
              'text-field': ['to-string', ['number-format', ['/', ['/', ['get', 'priceTotal'], ['get', 'point_count']], 10], { 'max-fraction-digits': 1 }]],
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 12,
              'visibility': isVisible
            }
          });

          map.addLayer({
            id: 'unclustered_point_' + elem.class,
            type: 'circle',
            source: elem.class,
            filter: ['!', ['has', 'point_count']],
            layout: { visibility: isVisible },
            paint: {
              'circle-color': [
                'step',
                ['get', 'price'],
                '#2cba00',
                priceBins[0],
                '#a3ff00',
                priceBins[1],
                '#fff400',
                priceBins[2],
                '#ffa700',
                priceBins[3],
                '#ff0000',
              ],
              'circle-radius': 20,
              'circle-stroke-width': 2,
              'circle-stroke-color': '#000',
            }
          });

          map.addLayer({
            id: 'unclustered_price_' + elem.class,
            type: 'symbol',
            source: elem.class,
            filter: ['!', ['has', 'point_count']],
            layout: {
              'text-field': ['to-string', ['number-format', ['/', ['get', 'price'], 10], { 'max-fraction-digits': 1 }]],
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 12,
              visibility: isVisible
            }
          });
          map.on('mouseenter', 'clusters_' + elem.class, () => {
            map.getCanvas().style.cursor = 'pointer'
          });

          map.on('mouseleave', 'clusters_' + elem.class, () => {
            map.getCanvas().style.cursor = ''
          });

          map.on('mouseenter', 'unclustered_point_' + elem.class, () => {
            map.getCanvas().style.cursor = 'pointer'
          });

          map.on('mouseleave', 'unclustered_point_' + elem.class, () => {
            map.getCanvas().style.cursor = ''
          });

          map.on('click', 'clusters_' + elem.class, (e) => {
            console.dir(e)
            const features = map.queryRenderedFeatures(e.point, {
              layers: ['clusters_' + elem.class]
            });

            const clusterId = features[0].properties.cluster_id;
            console.dir(clusterId)

            map.getSource(elem.class).getClusterLeaves(
              clusterId, 10, 0,
              (err, features) => {
                if (!err) {
                  const collection = turf.featureCollection(features);
                  const collectionBbox = turf.bbox(collection);
                  const collectionPolygon = turf.bboxPolygon(collectionBbox);
                  const collectionScaled = turf.transformScale(collectionPolygon, 1.4);
                  const collectionBboxScaled = turf.bbox(collectionScaled);
                  map.fitBounds(collectionBboxScaled);
                }
              });
          });

          map.on('click', 'unclustered_point_' + elem.class, (e) => {
            const coords = e.features[0].geometry.coordinates.slice();
            const properties = e.features[0].properties;
            mapSitePopup(coords, properties);
          });
        }
        map.addControl(
          new MapboxGeocoder({
            accessToken: MAPBOX_TOKEN,
            bbox: MAPBOX_BBOX,
            mapboxgl: mapboxgl,
            collapsed: true,
            clearOnBlur: true
          })
        );

        updateVisuals(mapBounds, map);

      };
    }
    catch (err) {
      console.log(err);
    }

  };

  function openSiteInfo(properties, coords) {
    setPopoutContent(<SiteInfo properties={properties} coords={coords} />);
    setPopoutExpanded(1);
  };

  function popoutClickHandler(expanded) {
    const el = document.getElementById("popoutContainer");
    if (expanded) {
      el.addEventListener("transitionend", () => {
        setPopoutContent(null);
      }, { once: true });
      setPopoutExpanded(0);
    } else {
      setPopoutExpanded(1);
    }
  }

  return (
    <Box id="liveMap">
      <MapMenu mapRef={mapRef}
        mapFlyTo={mapFlyTo}
        userRoutes={userRoutes}
        addRoute={addRoute}
      />
      <DataFilterButton fuelTypeFocus={fuelTypeFocus} setFuelTypeFocus={setFuelTypeFocus} brandsList={brandsList} brandFilter={brandFilter} setBrandFilter={setBrandFilter} />
      <Map ref={mapRef} onLoad={mapDataLoad}
        onMoveEnd={mapUpdate}
        initialViewState={mapInit}
        maxBounds={MAPBOX_BBOX}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        mapboxAccessToken={MAPBOX_TOKEN}
      >
        <GeolocateControl
          positionOptions={{ enableHighAccuracy: true }}
          fitBoundsOptions={{ maxZoom: 12 }}
          trackUserLocation={true}
        />
      </Map>
      
      <LowerPopout
        mapFlyTo={mapFlyTo}
        mapSitePopup={mapSitePopup}
        setFuelType={setFuelTypeFocus}
        clickHandler={popoutClickHandler}
        content={popoutContent}
        data={localPriceList}
        expanded={popoutExpanded}
        setExpanded={setPopoutExpanded} />
    </Box>
  )
};

function App() {

  return (
    <ChakraProvider theme={darkTheme}>
      <Grid id="main">
        <LivePanel />
      </Grid>
    </ChakraProvider>

  );
}

export default App;
